import { useEnum } from "@/api/services/global/enum";
import { InnerBox } from "@/components/elements/InnerBox";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { ERetirementReasonType } from "@/features/convert/enums/anytime";
import { TRetirementDocumentData } from "@/features/convert/types/documents/retirement-document/retirementDocument";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { Box, Grid, Stack, Typography } from "@mui/material";

type TConvertRetirementAcceptingDocumentInfoProps = {
  data: TRetirementDocumentData;
};

export const ConvertRetirementAcceptingDocumentInfo = ({ data }: TConvertRetirementAcceptingDocumentInfoProps) => {
  const { data: retirementReasonTypeOptions } = useEnum("ERetirementReasonType");
  const { data: modifyContractTerminateReasonForCompanyOptions } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: modifyContractTerminateReasonForEmployeeOptions } = useEnum("EModifyContractTerminateReasonForEmployee");

  const { data: currentSituationOptions } = useEnum("ECurrentSituation");
  const { data: planToContinueActivityOptions } = useEnum("EPlanToContinueActivity");
  const { data: measureOptions } = useEnum("EMeasure");
  const { data: specificCircumstanceOptions } = useEnum("ESpecificCircumstance");
  const { data: anyConsultationOptions } = useEnum("EAnyConsultation");
  const { data: planToChangeJobOptions } = useEnum("EPlanToChangeJob");
  const { data: supportTypeOptions } = useEnum("ESupportType");
  const { data: reasonReturnHomeOptions } = useEnum("EReasonReturnHome");
  const { data: planDateReturnOptions } = useEnum("EPlanDateReturn");
  const { data: planToMoveOptions } = useEnum("EPlanToMove");
  const { data: providedLegalProtectionOptions } = useEnum("EProvidedLegalProtection");

  const { documentInfo } = data;
  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">受入れ困難に係る届出</Typography>
        <Box maxHeight={600}>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="sub16Bold">大項目</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="sub16Bold">中項目</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="sub16Bold">入力内容</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 事由 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">事由</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">--</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">{findLabelByValue(retirementReasonTypeOptions, documentInfo.reasonType) || EMPTY_STR.TEXT}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
          {documentInfo?.reasonType === ERetirementReasonType.CONVENIENT_FOR_COMPANY ? (
            <>
              {/* A.特定技能所属機関の都合 */}
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">A.特定技能所属機関の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由の区分</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      {findLabelByValue(modifyContractTerminateReasonForCompanyOptions, documentInfo.changeContractCategoryType) || EMPTY_STR.TEXT}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">A.特定技能所属機関の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由の区分</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">{documentInfo.changeContractCategoryContent || EMPTY_STR.TEXT}</Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">A.特定技能所属機関の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由発生日</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      {documentInfo.changeContractDateOfOccurrence
                        ? dayjs(documentInfo.changeContractDateOfOccurrence).format(COMPACT_DATE_FORMAT)
                        : EMPTY_STR.TEXT}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">A.特定技能所属機関の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由の概要</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">{documentInfo.changeContractSummary || EMPTY_STR.TEXT}</Typography>
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <>
              {/* B. 特定技能外国人の都合 */}
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">B. 特定技能外国人の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由の区分</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      {findLabelByValue(modifyContractTerminateReasonForEmployeeOptions, documentInfo.convenienceCategoryType) || EMPTY_STR.TEXT}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">B. 特定技能外国人の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由の区分</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">{documentInfo.convenienceCategoryContent || EMPTY_STR.TEXT}</Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">B. 特定技能外国人の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由発生日</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">
                      {documentInfo.convenienceDateOfOccurrence
                        ? dayjs(documentInfo.convenienceDateOfOccurrence).format(COMPACT_DATE_FORMAT)
                        : EMPTY_STR.TEXT}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <Typography variant="body14">B. 特定技能外国人の都合</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body14">事由の概要</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body14">{documentInfo.convenienceSummary || EMPTY_STR.TEXT}</Typography>
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}

          {/* 特定技能外国人の現状 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能外国人の現状</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">--</Typography>
                </Grid>
                <Grid item xs={3}>
                  {findLabelByValue(currentSituationOptions, documentInfo.currentSituation) || EMPTY_STR.TEXT}
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 受け入れ継続のための措置 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">受け入れ継続のための措置</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">活動継続の意思</Typography>
                </Grid>
                <Grid item xs={3}>
                  {findLabelByValue(planToContinueActivityOptions, documentInfo.planToContinueActivity) || EMPTY_STR.TEXT}
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 受け入れ継続のための措置 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">受け入れ継続のための措置</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">活動継続の意思</Typography>
                </Grid>
                <Grid item xs={3}>
                  {documentInfo.planToContinueActivityContent || EMPTY_STR.TEXT}
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 受け入れ継続のための措置 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">受け入れ継続のための措置</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">措置内容</Typography>
                </Grid>
                <Grid item xs={3}>
                  {findLabelByValue(measureOptions, documentInfo.measuresTakenType) || EMPTY_STR.TEXT}
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 受け入れ継続のための措置 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">受け入れ継続のための措置</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">措置内容</Typography>
                </Grid>
                <Grid item xs={3}>
                  {documentInfo.measuresTakenContent || EMPTY_STR.TEXT}
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">氏名又は名称</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">{documentInfo.companyName || EMPTY_STR.TEXT}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">法人番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyIdentificationNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">郵便番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {documentInfo.companyZipCode ? convertNumberToZipCode(documentInfo.companyZipCode) : EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">都道府県</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  <Typography variant="body14">{documentInfo.companyProvince || EMPTY_STR.TEXT}</Typography>
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">住所</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyAddress || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 特定技能所属機関 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">特定技能所属機関</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">電話番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.companyTelNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">受入れ困難となるに至った経緯に係る説明書</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">具体的な事情（経緯、対応、退職、解雇理由）</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">
                  {findLabelByValue(specificCircumstanceOptions, documentInfo.specificCircumstances) || EMPTY_STR.TEXT}
                </Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14">受入れ困難となるに至った経緯に係る説明書</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">具体的な事情（経緯、対応、退職、解雇理由）</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.specificCircumstanceContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">退職に係る相談の有無、その対応</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{findLabelByValue(anyConsultationOptions, documentInfo.anyConsultation) || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">退職に係る相談の有無、その対応</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.anyConsultationContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">転職先、転職予定年月日(転職予定がある場合)</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{findLabelByValue(planToChangeJobOptions, documentInfo.plansToChangeJob) || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">転職先、転職予定年月日(転職予定がある場合)</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.plansToChangeJobContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">転職支援を実施する場合は支援の内容</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{findLabelByValue(supportTypeOptions, documentInfo.supportType) || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">転職支援を実施する場合は支援の内容</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.supportContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">帰国を希望している場合はその理由</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{findLabelByValue(reasonReturnHomeOptions, documentInfo.reasonReturnHome) || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">帰国を希望している場合はその理由</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.reasonReturnHomeContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">帰国予定年月日、航空券の手配状況</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{findLabelByValue(planDateReturnOptions, documentInfo.planDateReturn) || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">帰国予定年月日、航空券の手配状況</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.planDateReturnContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">退職後に転居する予定がある場合は転居先</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{findLabelByValue(planToMoveOptions, documentInfo.planToMove) || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">退職後に転居する予定がある場合は転居先</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.planToMoveContent || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">特定技能外国人の電話番号</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.telNumber || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">連絡先の名称(電話番号がない場合)</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body14">{documentInfo.contractName || EMPTY_STR.TEXT}</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 受入れ困難となるに至った経緯に係る説明書 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 受入れ困難となるに至った経緯に係る説明書 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">復帰予定年月日</Typography>
              </Grid>
              <Grid item xs={3}>
                {documentInfo.planedDateOfReturn ? dayjs(documentInfo.planedDateOfReturn).format(COMPACT_DATE_FORMAT) : EMPTY_STR.TEXT}
              </Grid>
            </Grid>
          </Stack>

          {/* 法的保護のための案内実施の有無 */}
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="body14"> 法的保護のための案内実施の有無 </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body14">--</Typography>
              </Grid>
              <Grid item xs={3}>
                {documentInfo.providedLegalProtection
                  ? documentInfo.providedLegalProtection?.map((item, index) => {
                      return (
                        <div key={index}>
                          <Typography variant="body14">{findLabelByValue(providedLegalProtectionOptions, item)}</Typography>
                          <br />
                        </div>
                      );
                    })
                  : EMPTY_STR.TEXT}
              </Grid>
            </Grid>
          </Stack>
          {/* ================================================ */}
        </Box>
      </Stack>
    </InnerBox>
  );
};
