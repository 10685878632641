import { useEnum } from "@/api/services/global/enum";
import { InnerBox } from "@/components/elements/InnerBox";
import { COMPACT_DATE_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import {
  EModifyContractTerminateReason,
  EModifyContractTerminateReasonForCompany,
  EModifyContractTerminateReasonForEmployee,
} from "@/features/convert/enums/anytime";
import { TRetirementContractDocumentData } from "@/features/convert/types/documents/retirement-document/retirementContractDocument";
import { dayjs } from "@/utils/dayjs";
import { convertNumberToZipCode } from "@/utils/input";
import { findLabelByValue } from "@/utils/object";
import { Box, Grid, Stack, Typography } from "@mui/material";

type TConvertRetirementContractDocumentInfoProps = {
  data: TRetirementContractDocumentData;
};

export const ConvertRetirementContractDocumentInfo = ({ data }: TConvertRetirementContractDocumentInfoProps) => {
  const { data: modifyContractTerminateReasonForCompanyOptions } = useEnum("EModifyContractTerminateReasonForCompany");
  const { data: modifyContractTerminateReasonOptions } = useEnum("EModifyContractTerminateReason");
  const { data: modifyContractTerminateReasonForEmployeeOptions } = useEnum("EModifyContractTerminateReasonForEmployee");
  const { documentInfo } = data;

  return (
    <InnerBox height="100%">
      <Stack gap={2}>
        <Typography variant="sub16Bold">特定技能雇用契約に係る届出</Typography>
        <Box maxHeight={600}>
          <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <Typography variant="sub16Bold">大項目</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="sub16Bold">中項目</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="sub16Bold">入力内容</Typography>
              </Grid>
            </Grid>
          </Stack>

          {/* 事由 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">事由</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">--</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">特定技能雇用契約の終了</Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
          {/* 契約の終了 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約の終了</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">終了年月日</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    {documentInfo.terminateContractDate ? dayjs(documentInfo.terminateContractDate).format(COMPACT_DATE_FORMAT) : EMPTY_STR.TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 契約の終了 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">契約の終了</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">終了の事由</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    {documentInfo.terminateContractReason
                      ? findLabelByValue(modifyContractTerminateReasonOptions, documentInfo.terminateContractReason)
                      : EMPTY_STR.TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 契約の終了 */}
          {documentInfo.terminateContractReason !== EModifyContractTerminateReason.EMPLOYMENT_CONTRACT_TERMINATION && (
            <>
              {documentInfo?.terminateContractReason === EModifyContractTerminateReason.CONVENIENT_FOR_COMPANY ? (
                <>
                  <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="body14">契約の終了</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body14">外国人の都合による終了の場合の詳細</Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant="body14">
                          {documentInfo.terminateContractReasonByCompany
                            ? findLabelByValue(modifyContractTerminateReasonForCompanyOptions, documentInfo.terminateContractReasonByCompany)
                            : EMPTY_STR.TEXT}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  {documentInfo?.terminateContractReasonByCompany === EModifyContractTerminateReasonForCompany.OTHER && (
                    <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Typography variant="body14">契約の終了</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body14">{documentInfo.terminateContractOtherByCompany || EMPTY_STR.TEXT}</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}
                </>
              ) : (
                <>
                  <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                    <Grid container spacing={3}>
                      <Grid item xs={5}>
                        <Typography variant="body14">契約の終了</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body14">外国人の都合による終了の場合の詳細</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body14">
                          {documentInfo.terminateContractReasonByEmployee
                            ? findLabelByValue(modifyContractTerminateReasonForEmployeeOptions, documentInfo.terminateContractReasonByEmployee)
                            : EMPTY_STR.TEXT}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                  {documentInfo?.terminateContractReasonByEmployee === EModifyContractTerminateReasonForEmployee.OTHER && (
                    <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
                      <Grid container spacing={3}>
                        <Grid item xs={5}>
                          <Typography variant="body14">契約の終了</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body14">{EMPTY_STR.TEXT}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body14">{documentInfo.terminateContractOtherReasonByEmployee || EMPTY_STR.TEXT}</Typography>
                        </Grid>
                      </Grid>
                    </Stack>
                  )}
                </>
              )}
            </>
          )}

          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">氏名又は名称</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.companyName || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">法人番号</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.companyIdentificationNumber || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">郵便番号</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    {documentInfo.companyZipCode ? convertNumberToZipCode(documentInfo.companyZipCode) : EMPTY_STR.TEXT}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">都道府県</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.companyProvince || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">住所（都道府県以外）</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.companyAddress || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 特定技能所属機関 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">特定技能所属機関</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">電話番号</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.companyTelNumber || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 届出担当者 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">届出担当者</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">--</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.supporterName || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>

          {/* 届出担当者 */}
          <>
            <Stack paddingX={2} paddingY={1} borderBottom="1px solid #E5E5E5">
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <Typography variant="body14">届出担当者</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body14">電話番号</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body14">
                    <Typography variant="body14">{documentInfo.supporterTelNumber || EMPTY_STR.TEXT}</Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </>
        </Box>
      </Stack>
    </InnerBox>
  );
};
