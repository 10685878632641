import {
  TCreateCompanySpecificBusinessClassificationReqDto,
  TCreateCompanySpecificBusinessClassificationResDto,
} from "@/api/services/global/master/dtos/companySpecificIndustry/create-company-business-classification.dto";
import {
  TCreateCompanySpecificIndustryReqDto,
  TCreateCompanySpecificIndustryResDto,
} from "@/api/services/global/master/dtos/companySpecificIndustry/create-company-specific-industry.dto";
import { TGetCompanySpecificIndustryListResDto } from "@/api/services/global/master/dtos/companySpecificIndustry/get-master-company-specific-industry-list.dto";
import {
  TUpdateCompanySpecificBusinessClassificationReqDto,
  TUpdateCompanySpecificBusinessClassificationResDto,
} from "@/api/services/global/master/dtos/companySpecificIndustry/update-company-business-classification.dto";
import {
  TUpdateCompanySpecificIndustryReqDto,
  TUpdateCompanySpecificIndustryResDto,
} from "@/api/services/global/master/dtos/companySpecificIndustry/update-company-specific-industry.dto";
import { TCreateLanguageTestReqDto, TCreateLanguageTestResDto } from "@/api/services/global/master/dtos/create-language-test.dto";
import { TCreateLivingRelationshipReqDto, TCreateLivingRelationshipResDto } from "@/api/services/global/master/dtos/create-living-relationship.dto";
import {
  TCreateLocalGovernmentCounterReqDto,
  TCreateLocalGovernmentCounterResDto,
} from "@/api/services/global/master/dtos/create-local-government-counter.dto";
import { TCreateMainIndustryReqDto, TCreateMainIndustryResDto } from "@/api/services/global/master/dtos/create-main-industry.dto";
import { TCreateMainOccupationReqDto, TCreateMainOccupationResDto } from "@/api/services/global/master/dtos/create-main-occupation.dto";

import { TCreateSkillTestReqDto, TCreateSkillTestResDto } from "@/api/services/global/master/dtos/create-skill-test.dto";
import { TGetLanguageTestListResDto } from "@/api/services/global/master/dtos/get-master-language-test-list.dto";
import { TGetMasterListResDto } from "@/api/services/global/master/dtos/get-master-list.dto";
import { TGetLivingRelationshipListResDto } from "@/api/services/global/master/dtos/get-master-living-relationship-list.dto";
import { TGetLocalGovernmentCounterListResDto } from "@/api/services/global/master/dtos/get-master-local-government-counter-list.dto";
import { TGetMainIndustryListResDto } from "@/api/services/global/master/dtos/get-master-main-industry-list.dto";
import { TGetMainOccupationListResDto } from "@/api/services/global/master/dtos/get-master-main-occupation-list.dto";
import { TGetSkillTestListResDto } from "@/api/services/global/master/dtos/get-master-skill-test-list.dto";
import { TCreateJobTypeReqDto, TCreateJobTypeResDto } from "@/api/services/global/master/dtos/jobType/create-job-type.dto";
import { TCreateMajorReqDto, TCreateMajorResDto } from "@/api/services/global/master/dtos/jobType/create-major.dto";
import { TGetMajorListResDto } from "@/api/services/global/master/dtos/jobType/get-master-major-list.dto";
import { TUpdateJobTypeReqDto, TUpdateJobTypeResDto } from "@/api/services/global/master/dtos/jobType/update-job-type.dto";
import { TUpdateMajorReqDto, TUpdateMajorResDto } from "@/api/services/global/master/dtos/jobType/update-major.dto";
import { TCreateNationalityReqDto, TCreateNationalityResDto } from "@/api/services/global/master/dtos/nationality/create-nationality.dto";
import { TCreateRegionReqDto, TCreateRegionResDto } from "@/api/services/global/master/dtos/nationality/create-region.dto";
import { TGetNationalityListResDto } from "@/api/services/global/master/dtos/nationality/get-master-nationality-list.dto";
import { TGetRegionListResDto } from "@/api/services/global/master/dtos/nationality/get-master-region-list.dto";
import { TUpdateNationalityReqDto, TUpdateNationalityResDto } from "@/api/services/global/master/dtos/nationality/update-nationality.dto";
import {
  TUpdateOrderNationalityListReqDto,
  TUpdateOrderNationalityListResDto,
} from "@/api/services/global/master/dtos/nationality/update-order-nationality-list.dto";
import { TUpdateRegionReqDto, TUpdateRegionResDto } from "@/api/services/global/master/dtos/nationality/update-region.dto";
import {
  TCreateResidencePeriodReqDto,
  TCreateResidencePeriodResDto,
} from "@/api/services/global/master/dtos/residencePeriod/create-residence-period.dto";
import {
  TCreateResidenceStatusReqDto,
  TCreateResidenceStatusResDto,
} from "@/api/services/global/master/dtos/residencePeriod/create-residence-status.dto";
import { TGetResidenceStatusListResDto } from "@/api/services/global/master/dtos/residencePeriod/get-master-residence-status-list.dto";
import {
  TUpdateResidencePeriodReqDto,
  TUpdateResidencePeriodResDto,
} from "@/api/services/global/master/dtos/residencePeriod/update-residence-period.dto";
import {
  TUpdateResidenceStatusReqDto,
  TUpdateResidenceStatusResDto,
} from "@/api/services/global/master/dtos/residencePeriod/update-residence-status.dto";
import {
  TCreateBusinessClassificationReqDto,
  TCreateBusinessClassificationResDto,
} from "@/api/services/global/master/dtos/specificIndustry/create-business-classification.dto";
import {
  TCreateSpecificIndustryReqDto,
  TCreateSpecificIndustryResDto,
} from "@/api/services/global/master/dtos/specificIndustry/create-specific-industry.dto";
import { TGetSpecificIndustryListResDto } from "@/api/services/global/master/dtos/specificIndustry/get-master-specific-industry-list.dto";
import {
  TUpdateBusinessClassificationReqDto,
  TUpdateBusinessClassificationResDto,
} from "@/api/services/global/master/dtos/specificIndustry/update-business-classification.dto";
import {
  TUpdateSpecificIndustryReqDto,
  TUpdateSpecificIndustryResDto,
} from "@/api/services/global/master/dtos/specificIndustry/update-specific-industry.dto";

import { TUpdateLanguageTestReqDto, TUpdateLanguageTestResDto } from "@/api/services/global/master/dtos/update-language-test.dto";
import { TUpdateLivingRelationshipReqDto, TUpdateLivingRelationshipResDto } from "@/api/services/global/master/dtos/update-living-relationship.dto";
import {
  TUpdateLocalGovernmentCounterReqDto,
  TUpdateLocalGovernmentCounterResDto,
} from "@/api/services/global/master/dtos/update-local-government-counter.dto";
import { TUpdateMainIndustryReqDto, TUpdateMainIndustryResDto } from "@/api/services/global/master/dtos/update-main-industry.dto";
import { TUpdateMainOccupationReqDto, TUpdateMainOccupationResDto } from "@/api/services/global/master/dtos/update-main-occupation.dto";
import { TUpdateSkillTestReqDto, TUpdateSkillTestResDto } from "@/api/services/global/master/dtos/update-skill-test.dto";
import { QUERY_KEYS } from "@/constants/queryKeys";
import { globalApi } from "@/utils/api";
import { toNumber } from "@/utils/number";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

//get-master-list
export const fetchMasterListApi = async (): Promise<TGetMasterListResDto> => {
  const rs = await globalApi.get<TGetMasterListResDto>(`/master`);
  return rs.data;
};

export const useGetMasterDataList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_DATA],
    queryFn: () => fetchMasterListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//nationalities
export const fetchNationalityListApi = async (): Promise<TGetNationalityListResDto> => {
  const rs = await globalApi.get<TGetNationalityListResDto>(`/master/regions/nationalities`);
  return rs.data;
};

export const fetchRegionListApi = async (): Promise<TGetRegionListResDto> => {
  const rs = await globalApi.get<TGetRegionListResDto>(`/master/regions`);
  return rs.data;
};

export const createRegionApi = async (payload: TCreateRegionReqDto): Promise<TCreateRegionResDto> => {
  const rs = await globalApi.post<TCreateRegionResDto>("/master/regions", payload);
  return rs.data;
};

export const createNationalityApi = async (payload: TCreateNationalityReqDto): Promise<TCreateNationalityResDto> => {
  const rs = await globalApi.post<TCreateNationalityResDto>(`/master/regions/${payload.regionId}/nationalities`, payload);
  return rs.data;
};

export const updateNationalityApi = async (nationalityId: number, payload: TUpdateNationalityReqDto): Promise<TUpdateNationalityResDto> => {
  const rs = await globalApi.put<TUpdateNationalityResDto>(`/master/regions/${payload.regionId}/nationalities/${nationalityId}`, payload);
  return rs.data;
};

export const updateOrderNationalityListApi = async (payload: TUpdateOrderNationalityListReqDto): Promise<TUpdateOrderNationalityListResDto> => {
  const rs = await globalApi.put<TUpdateOrderNationalityListResDto>(`/master/regions/nationalities/order`, payload);
  return rs.data;
};

export const setHiddenNationalityApi = async (
  regionId: number,
  nationalityId: number,
  payload: { isHidden: boolean },
): Promise<TUpdateNationalityResDto> => {
  const rs = await globalApi.put<TUpdateNationalityResDto>(`/master/regions/${regionId}/nationalities/${nationalityId}/set-hidden`, payload);
  return rs.data;
};

export const updateRegionApi = async (payload: TUpdateRegionReqDto): Promise<TUpdateRegionResDto> => {
  const rs = await globalApi.put<TUpdateRegionResDto>("/master/regions", payload);
  return rs.data;
};

export const setHiddenRegionApi = async (regionId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/regions/${regionId}/set-hidden`, payload);
  return rs.data;
};

export const useGetNationalityList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_NATIONALITY],
    queryFn: () => fetchNationalityListApi(),
  });

  const nationalityOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.nationalityName, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], nationalityOptions, ...rest };
};

export const useGetRegionList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_REGION],
    queryFn: () => fetchRegionListApi(),
  });

  const nationalityOptions = useMemo(
    () =>
      data
        ? data.data.flatMap((item) => item.nationalities).map((item) => ({ label: item.nationalityName, value: item.id, hidden: item.isHidden }))
        : [],
    [data],
  );

  const regionOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.regionName, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], nationalityOptions, regionOptions, ...rest };
};

//job-types
export const fetchMajorListApi = async (): Promise<TGetMajorListResDto> => {
  const rs = await globalApi.get<TGetMajorListResDto>(`/master/majors`);
  return rs.data;
};

export const createMajorApi = async (payload: TCreateMajorReqDto): Promise<TCreateMajorResDto> => {
  const rs = await globalApi.post<TCreateMajorResDto>("/master/majors", payload);
  return rs.data;
};

export const createJobTypeApi = async (payload: TCreateJobTypeReqDto): Promise<TCreateJobTypeResDto> => {
  const rs = await globalApi.post<TCreateJobTypeResDto>(`/master/majors/${payload.majorId}/job-types`, payload);
  return rs.data;
};

export const updateJobTypeApi = async (jobTypeId: number, payload: TUpdateJobTypeReqDto): Promise<TUpdateJobTypeResDto> => {
  const rs = await globalApi.put<TUpdateJobTypeResDto>(`/master/majors/${payload.majorId}/job-types/${jobTypeId}`, payload);
  return rs.data;
};
export const setHiddenJobTypeApi = async (majorId: number, jobTypeId: number, payload: { isHidden: boolean }): Promise<TUpdateJobTypeResDto> => {
  const rs = await globalApi.put<TUpdateJobTypeResDto>(`/master/majors/${majorId}/job-types/${jobTypeId}/set-hidden`, payload);
  return rs.data;
};

export const updateMajorApi = async (payload: TUpdateMajorReqDto): Promise<TUpdateMajorResDto> => {
  const rs = await globalApi.put<TUpdateMajorResDto>("/master/majors", payload);
  return rs.data;
};

export const setHiddenMajorApi = async (majorId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/majors/${majorId}/set-hidden`, payload);
  return rs.data;
};

export const useGetMajorList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAJOR],
    queryFn: () => fetchMajorListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//residence-periods
export const fetchResidenceStatusListApi = async (): Promise<TGetResidenceStatusListResDto> => {
  const rs = await globalApi.get<TGetResidenceStatusListResDto>(`/master/residence-statuses`);
  return rs.data;
};

export const createResidenceStatusApi = async (payload: TCreateResidenceStatusReqDto): Promise<TCreateResidenceStatusResDto> => {
  const rs = await globalApi.post<TCreateResidenceStatusResDto>("/master/residence-statuses", payload);
  return rs.data;
};

export const createResidencePeriodApi = async (payload: TCreateResidencePeriodReqDto): Promise<TCreateResidencePeriodResDto> => {
  const rs = await globalApi.post<TCreateResidencePeriodResDto>(`/master/residence-statuses/${payload.residenceStatusId}/residence-periods`, payload);
  return rs.data;
};

export const updateResidencePeriodApi = async (
  residencePeriodId: number,
  payload: TUpdateResidencePeriodReqDto,
): Promise<TUpdateResidencePeriodResDto> => {
  const rs = await globalApi.put<TUpdateResidencePeriodResDto>(
    `/master/residence-statuses/${payload.residenceStatusId}/residence-periods/${residencePeriodId}`,
    payload,
  );
  return rs.data;
};

export const setHiddenResidencePeriodApi = async (
  residenceStatusId: number,
  residencePeriodId: number,
  payload: { isHidden: boolean },
): Promise<TUpdateResidencePeriodResDto> => {
  const rs = await globalApi.put<TUpdateResidencePeriodResDto>(
    `/master/residence-statuses/${residenceStatusId}/residence-periods/${residencePeriodId}/set-hidden`,
    payload,
  );
  return rs.data;
};

export const updateResidenceStatusApi = async (payload: TUpdateResidenceStatusReqDto): Promise<TUpdateResidenceStatusResDto> => {
  const rs = await globalApi.put<TUpdateResidenceStatusResDto>("/master/residence-statuses", payload);
  return rs.data;
};

export const setHiddenResidenceStatusApi = async (residenceStatusId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/residence-statuses/${residenceStatusId}/set-hidden`, payload);
  return rs.data;
};

export const useGetResidenceStatusList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_RESIDENCE_STATUS],
    queryFn: () => fetchResidenceStatusListApi(),
  });

  const residenceStatusOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.residenceStatus, value: item.id, hidden: item.isHidden })) : []),
    [data],
  );

  return { data: data?.data ?? [], residenceStatusOptions, ...rest };
};

//regular-renew-visa-specified-industry
export const fetchSpecificIndustryListApi = async (): Promise<TGetSpecificIndustryListResDto> => {
  const rs = await globalApi.get<TGetSpecificIndustryListResDto>(`/master/specific-industries`);
  return rs.data;
};

export const createSpecificIndustryApi = async (payload: TCreateSpecificIndustryReqDto): Promise<TCreateSpecificIndustryResDto> => {
  const rs = await globalApi.post<TCreateSpecificIndustryResDto>("/master/specific-industries", payload);
  return rs.data;
};

export const createBusinessClassificationApi = async (payload: TCreateBusinessClassificationReqDto): Promise<TCreateBusinessClassificationResDto> => {
  const rs = await globalApi.post<TCreateBusinessClassificationResDto>(
    `/master/specific-industries/${payload.specificIndustryId}/specific-business-classifications`,
    payload,
  );
  return rs.data;
};

export const updateBusinessClassificationApi = async (
  businessClassificationId: number,
  payload: TUpdateBusinessClassificationReqDto,
): Promise<TUpdateBusinessClassificationResDto> => {
  const rs = await globalApi.put<TUpdateBusinessClassificationResDto>(
    `/master/specific-industries/${payload.specificIndustryId}/specific-business-classifications/${businessClassificationId}`,
    payload,
  );
  return rs.data;
};

export const setHiddenBusinessClassificationApi = async (
  specificIndustryId: number,
  businessClassificationId: number,
  payload: { isHidden: boolean },
): Promise<TUpdateBusinessClassificationResDto> => {
  const rs = await globalApi.put<TUpdateBusinessClassificationResDto>(
    `/master/specific-industries/${specificIndustryId}/specific-business-classifications/${businessClassificationId}/set-hidden`,
    payload,
  );
  return rs.data;
};

export const updateSpecificIndustryApi = async (payload: TUpdateSpecificIndustryReqDto): Promise<TUpdateSpecificIndustryResDto> => {
  const rs = await globalApi.put<TUpdateSpecificIndustryResDto>("/master/specific-industries", payload);
  return rs.data;
};

export const updateSpecificIndustryOrderApi = async (payload: TUpdateSpecificIndustryReqDto): Promise<TUpdateSpecificIndustryResDto> => {
  const rs = await globalApi.put<TUpdateSpecificIndustryResDto>("/master/specific-industries/order", payload);
  return rs.data;
};

export const setHiddenSpecificIndustryApi = async (specificIndustryId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/specific-industries/${specificIndustryId}/set-hidden`, payload);
  return rs.data;
};

export const useGetSpecificIndustryList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_SPECIFIC_INDUSTRY],
    queryFn: () => fetchSpecificIndustryListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//living-relationship
export const fetchLivingRelationshipListApi = async (): Promise<TGetLivingRelationshipListResDto> => {
  const rs = await globalApi.get<TGetLivingRelationshipListResDto>("/master/living-relationships");
  return rs.data;
};

export const createLivingRelationshipApi = async (payload: TCreateLivingRelationshipReqDto): Promise<TCreateLivingRelationshipResDto> => {
  const rs = await globalApi.post<TCreateLivingRelationshipResDto>("/master/living-relationships", payload);
  return rs.data;
};

export const updateLivingRelationshipApi = async (payload: TUpdateLivingRelationshipReqDto): Promise<TUpdateLivingRelationshipResDto> => {
  const rs = await globalApi.put<TUpdateLivingRelationshipResDto>("/master/living-relationships", payload);
  return rs.data;
};

export const setHiddenLivingRelationshipApi = async (livingRelationshipId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/living-relationships/${livingRelationshipId}/set-hidden`, payload);
  return rs.data;
};

export const useGetLivingRelationshipList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_LIVING_RELATIONSHIP],
    queryFn: () => fetchLivingRelationshipListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//main-occupation
export const fetchMainOccupationListApi = async (): Promise<TGetMainOccupationListResDto> => {
  const rs = await globalApi.get<TGetMainOccupationListResDto>("/master/main-occupations");
  return rs.data;
};

export const createMainOccupationApi = async (payload: TCreateMainOccupationReqDto): Promise<TCreateMainOccupationResDto> => {
  const rs = await globalApi.post<TCreateMainOccupationResDto>("/master/main-occupations", payload);
  return rs.data;
};

export const updateMainOccupationApi = async (payload: TUpdateMainOccupationReqDto): Promise<TUpdateMainOccupationResDto> => {
  const rs = await globalApi.put<TUpdateMainOccupationResDto>("/master/main-occupations", payload);
  return rs.data;
};

export const setHiddenMainOccupationApi = async (mainOccupationId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/main-occupations/${mainOccupationId}/set-hidden`, payload);
  return rs.data;
};

export const useGetMainOccupationList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_OCCUPATION],
    queryFn: () => fetchMainOccupationListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//main-industry
export const fetchMainIndustryListApi = async (): Promise<TGetMainIndustryListResDto> => {
  const rs = await globalApi.get<TGetMainIndustryListResDto>("/master/main-industries");
  return rs.data;
};

export const createMainIndustryApi = async (payload: TCreateMainIndustryReqDto): Promise<TCreateMainIndustryResDto> => {
  const rs = await globalApi.post<TCreateMainIndustryResDto>("/master/main-industries", payload);
  return rs.data;
};

export const updateMainIndustryApi = async (payload: TUpdateMainIndustryReqDto): Promise<TUpdateMainIndustryResDto> => {
  const rs = await globalApi.put<TUpdateMainIndustryResDto>("/master/main-industries", payload);
  return rs.data;
};

export const setHiddenMainIndustryApi = async (mainIndustryId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/main-industries/${mainIndustryId}/set-hidden`, payload);
  return rs.data;
};

export const useGetMainIndustryList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_MAIN_INDUSTRY],
    queryFn: () => fetchMainIndustryListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//local-government-counter
export const fetchLocalGovernmentCounterListApi = async (): Promise<TGetLocalGovernmentCounterListResDto> => {
  const rs = await globalApi.get<TGetLocalGovernmentCounterListResDto>("/master/local-government-counters");
  return rs.data;
};

export const createLocalGovernmentCounterApi = async (payload: TCreateLocalGovernmentCounterReqDto): Promise<TCreateLocalGovernmentCounterResDto> => {
  const rs = await globalApi.post<TCreateLocalGovernmentCounterResDto>("/master/local-government-counters", payload);
  return rs.data;
};

export const updateLocalGovernmentCounterApi = async (payload: TUpdateLocalGovernmentCounterReqDto): Promise<TUpdateLocalGovernmentCounterResDto> => {
  const rs = await globalApi.put<TUpdateLocalGovernmentCounterResDto>("/master/local-government-counters", payload);
  return rs.data;
};

export const setHiddenLocalGovernmentCounterApi = async (localGovernmentCounterId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/local-government-counters/${localGovernmentCounterId}/set-hidden`, payload);
  return rs.data;
};

export const useGetLocalGovernmentCounterList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_LOCAL_GOVERNMENT_COUNTER],
    queryFn: () => fetchLocalGovernmentCounterListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//language-test
export const fetchLanguageTestListApi = async (): Promise<TGetLanguageTestListResDto> => {
  const rs = await globalApi.get<TGetLanguageTestListResDto>("/master/language-tests");
  return rs.data;
};

export const createLanguageTestApi = async (payload: TCreateLanguageTestReqDto): Promise<TCreateLanguageTestResDto> => {
  const rs = await globalApi.post<TCreateLanguageTestResDto>("/master/language-tests", payload);
  return rs.data;
};

export const updateLanguageTestApi = async (payload: TUpdateLanguageTestReqDto): Promise<TUpdateLanguageTestResDto> => {
  const rs = await globalApi.put<TUpdateLanguageTestResDto>("/master/language-tests", payload);
  return rs.data;
};

export const setHiddenLanguageTestApi = async (languageTestId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/language-tests/${languageTestId}/set-hidden`, payload);
  return rs.data;
};

export const useGetLanguageTestList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_LANGUAGE_TEST],
    queryFn: () => fetchLanguageTestListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//skill-test
export const fetchSkillTestListApi = async (): Promise<TGetSkillTestListResDto> => {
  const rs = await globalApi.get<TGetSkillTestListResDto>("/master/skill-tests");
  return rs.data;
};

export const createSkillTestApi = async (payload: TCreateSkillTestReqDto): Promise<TCreateSkillTestResDto> => {
  const rs = await globalApi.post<TCreateSkillTestResDto>("/master/skill-tests", payload);
  return rs.data;
};

export const updateSkillTestApi = async (payload: TUpdateSkillTestReqDto): Promise<TUpdateSkillTestResDto> => {
  const rs = await globalApi.put<TUpdateSkillTestResDto>("/master/skill-tests", payload);
  return rs.data;
};

export const setHiddenSkillTestApi = async (skillTestId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/skill-tests/${skillTestId}/set-hidden`, payload);
  return rs.data;
};

export const useGetSkillTestList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_SKILL_TEST],
    queryFn: () => fetchSkillTestListApi(),
  });

  return { data: data?.data ?? [], ...rest };
};

//company-specific-industry
export const fetchCompanySpecificIndustryListApi = async (): Promise<TGetCompanySpecificIndustryListResDto> => {
  const rs = await globalApi.get<TGetCompanySpecificIndustryListResDto>(`/master/company-specific-industries`);
  return rs.data;
};

export const createCompanySpecificIndustryApi = async (
  payload: TCreateCompanySpecificIndustryReqDto,
): Promise<TCreateCompanySpecificIndustryResDto> => {
  const rs = await globalApi.post<TCreateCompanySpecificIndustryResDto>("/master/company-specific-industries", payload);
  return rs.data;
};

export const createCompanySpecificBusinessClassificationApi = async (
  payload: TCreateCompanySpecificBusinessClassificationReqDto,
): Promise<TCreateCompanySpecificBusinessClassificationResDto> => {
  const rs = await globalApi.post<TCreateCompanySpecificBusinessClassificationResDto>(
    `/master/company-specific-industries/${payload.companySpecificIndustryId}/company-specific-business-classifications`,
    payload,
  );
  return rs.data;
};

export const updateCompanySpecificBusinessClassificationApi = async (
  companySpecificBusinessClassificationId: number,
  payload: TUpdateCompanySpecificBusinessClassificationReqDto,
): Promise<TUpdateCompanySpecificBusinessClassificationResDto> => {
  const rs = await globalApi.put<TUpdateCompanySpecificBusinessClassificationResDto>(
    `/master/company-specific-industries/${payload.companySpecificIndustryId}/company-specific-business-classifications/${companySpecificBusinessClassificationId}`,
    payload,
  );
  return rs.data;
};
export const setHiddenCompanySpecificBusinessClassificationApi = async (
  companySpecificIndustryId: number,
  companySpecificBusinessClassificationId: number,
  payload: { isHidden: boolean },
): Promise<TUpdateCompanySpecificBusinessClassificationResDto> => {
  const rs = await globalApi.put<TUpdateCompanySpecificBusinessClassificationResDto>(
    `/master/company-specific-industries/${companySpecificIndustryId}/company-specific-business-classifications/${companySpecificBusinessClassificationId}/set-hidden`,
    payload,
  );
  return rs.data;
};

export const updateCompanySpecificIndustryApi = async (
  payload: TUpdateCompanySpecificIndustryReqDto,
): Promise<TUpdateCompanySpecificIndustryResDto> => {
  const rs = await globalApi.put<TUpdateCompanySpecificIndustryResDto>("/master/company-specific-industries", payload);
  return rs.data;
};

export const setHiddenCompanySpecificIndustryApi = async (companySpecificIndustryId: number, payload: { isHidden: boolean }): Promise<boolean> => {
  const rs = await globalApi.put<boolean>(`/master/company-specific-industries/${companySpecificIndustryId}/set-hidden`, payload);
  return rs.data;
};

export const useGetCompanySpecificIndustryList = () => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEYS.GLOBAL.MASTER_COMPANY_SPECIFIC_INDUSTRY],
    queryFn: () => fetchCompanySpecificIndustryListApi(),
  });

  const companySpecificIndustryOptions = useMemo(
    () => (data ? data.data.map((item) => ({ label: item.companySpecificIndustry, value: toNumber(item.id), hidden: item.isHidden })) : []),
    [data],
  );

  const getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry = useCallback(
    (companySpecificIndustryId: number | string) => {
      const foundCompanySpecificIndustry = data ? data.data.find((item) => toNumber(item.id) === toNumber(companySpecificIndustryId)) : undefined;
      if (!foundCompanySpecificIndustry) return [];
      const options =
        foundCompanySpecificIndustry?.companySpecificBusinessClassifications?.map((item) => ({
          label: item.companySpecificBusinessClassificationName,
          value: item.id,
          hidden: item.isHidden,
        })) || [];
      return options;
    },
    [data],
  );

  return {
    data: data?.data ?? [],
    companySpecificIndustryOptions,
    getCompanySpecificBusinessClassificationListOptionByCompanySpecificIndustry,
    ...rest,
  };
};
