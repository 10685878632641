import { TMasterSpecificBusinessClassification } from "@/api/entities/master-specific-business-classification.entity";
import { IconPlusCircle } from "@/assets/icons";
import { IconOrderTable } from "@/assets/icons/IconOrderTable";
import { Button } from "@/components/elements/Button";
import { DATE_TIME_FORMAT } from "@/constants/datetime";
import { EMPTY_STR } from "@/constants/string";
import { BusinessClassificationFormModal } from "@/features/master-data/components/SpecificIndustry/BusinessClassificationFormModal";
import { HiddenBusinessClassificationModal } from "@/features/master-data/components/SpecificIndustry/HiddenBusinessClassificationModal";
import { useVisible } from "@/hooks/useVisible";
import { background, blue, button, divider, green, text } from "@/theme/colors";
import { rounded } from "@/theme/variables";
import { dayjs } from "@/utils/dayjs";
import { Box, Stack, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";

type TUpdateSpecificIndustryFormTableProps = {
  selectedBusinessClassificationRows: TMasterSpecificBusinessClassification[];
  setSelectedBusinessClassificationRows: React.Dispatch<React.SetStateAction<TMasterSpecificBusinessClassification[]>>;
  existingSpecificIndustryId: number;
  existingRegularAnyTimeSpecificIndustry: string;
  existingCompanySpecificIndustry: string;
  existingResidenceStatus: string;
  existingCompanySpecificIndustryId: number;
  existingRenewVisaSpecificIndustry: string;
  existingFgSpecifiedIndustryRenewVisaCsvCode: string;
};

export const UpdateSpecificIndustryFormTable: FC<TUpdateSpecificIndustryFormTableProps> = ({
  selectedBusinessClassificationRows,
  setSelectedBusinessClassificationRows,
  existingSpecificIndustryId,
  existingRegularAnyTimeSpecificIndustry,
  existingRenewVisaSpecificIndustry,
  existingFgSpecifiedIndustryRenewVisaCsvCode,
  existingCompanySpecificIndustryId,
  existingCompanySpecificIndustry,
  existingResidenceStatus,
}) => {
  const businessClassificationFormModal = useVisible();

  const [selectedBusinessClassification, setSelectedBusinessClassification] = useState<TMasterSpecificBusinessClassification>();

  const [hiddenBusinessClassification, setHiddenBusinessClassification] = useState<TMasterSpecificBusinessClassification>();

  // Handle row dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !result.source || result.source.index === result.destination.index) return;
    const reorderedRows = Array.from(selectedBusinessClassificationRows);
    const [removed] = reorderedRows.splice(result.source.index, 1);
    reorderedRows.splice(result.destination.index, 0, removed);
    setSelectedBusinessClassificationRows(reorderedRows);
  };

  return (
    <>
      <Stack gap={1} flexDirection="column">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="tableRows" direction="vertical">
            {(provided) => (
              <StyledTable {...provided.droppableProps} ref={provided.innerRef}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>業務区分（会社情報用）</TableCell>
                      <TableCell>業務区分（外国人用、期間更新の表記）</TableCell>
                      <TableCell>期間更新CSVのコード（列FH）</TableCell>
                      <TableCell>業務区分（随時届出）</TableCell>
                      <TableCell>随時届出CSVのコード</TableCell>
                      <TableCell>最終更新日</TableCell>
                      <TableCell sx={{ position: "sticky", textAlign: "center !important", right: "100px", bgcolor: background.white }}>
                        有効／無効
                      </TableCell>
                      <TableCell sx={{ position: "sticky", textAlign: "center !important", right: "0", bgcolor: background.white }}>編集</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedBusinessClassificationRows.map((row, index) => (
                      <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                        {(provided) => (
                          <TableRow ref={provided.innerRef} {...provided.draggableProps}>
                            <TableCell sx={{ minWidth: "40px", paddingLeft: "10px !important" }} {...provided.dragHandleProps}>
                              <IconOrderTable color={blue[80]} />
                            </TableCell>
                            <TableCell sx={{ minWidth: "290px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.companySpecificBusinessClassification.companySpecificBusinessClassificationName || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "560px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.specificBusinessClassificationName || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "250px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.fhSpecificBusinessClassificationRenewVisaCsvCode || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "600px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.anyTimeSpecificBusinessClassificationName || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.specificBusinessClassificationAnyTimeCsvCode || EMPTY_STR.TEXT}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ minWidth: "150px", paddingLeft: "8px !important" }}>
                              <Typography color={row.isHidden ? text.tertiary : text.primary} variant="body14">
                                {row.updatedAt ? dayjs(row.updatedAt).format(DATE_TIME_FORMAT) : EMPTY_STR.DATE}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign: "center !important",
                                minWidth: "120px",
                                position: "sticky",
                                right: "100px",
                                bgcolor: background.white,
                              }}
                            >
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={() => {
                                  setHiddenBusinessClassification(row);
                                }}
                              >
                                {row.isHidden ? "有効化" : "無効化"}
                              </Button>
                            </TableCell>
                            <TableCell
                              sx={{
                                minWidth: "100px",
                                textAlign: "center !important",
                                paddingX: "4px !important",
                                position: "sticky",
                                right: "0px",
                                bgcolor: background.white,
                              }}
                            >
                              <Button
                                size="sm"
                                onClick={() => {
                                  setSelectedBusinessClassification(row);
                                  businessClassificationFormModal.open();
                                }}
                              >
                                編集
                              </Button>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </StyledTable>
            )}
          </Droppable>
        </DragDropContext>
        <AddRowButton onClick={() => businessClassificationFormModal.open()}>
          <IconPlusCircle />
          <Typography variant="cap12">{"行を追加する"}</Typography>
        </AddRowButton>
      </Stack>

      {businessClassificationFormModal.visible && (
        <BusinessClassificationFormModal
          onClose={() => {
            businessClassificationFormModal.close();
            setSelectedBusinessClassification(undefined);
          }}
          isOpen={businessClassificationFormModal.visible}
          selectedBusinessClassification={selectedBusinessClassification}
          setSelectedBusinessClassificationRows={setSelectedBusinessClassificationRows}
          existingSpecificIndustryId={existingSpecificIndustryId}
          existingCompanySpecificIndustryId={existingCompanySpecificIndustryId}
          existingRegularAnyTimeSpecificIndustry={existingRegularAnyTimeSpecificIndustry}
          existingRenewVisaSpecificIndustry={existingRenewVisaSpecificIndustry}
          existingFgSpecifiedIndustryRenewVisaCsvCode={existingFgSpecifiedIndustryRenewVisaCsvCode}
          existingCompanySpecificIndustry={existingCompanySpecificIndustry}
          existingResidenceStatus={existingResidenceStatus}
        />
      )}

      <HiddenBusinessClassificationModal
        hiddenBusinessClassification={hiddenBusinessClassification}
        setHiddenBusinessClassification={setHiddenBusinessClassification}
        setSelectedBusinessClassificationRows={setSelectedBusinessClassificationRows}
      />
    </>
  );
};

const AddRowButton = styled(Box)`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const StyledTable = styled(Box)`
  border-radius: ${rounded.sm};
  overflow: auto;
  max-height: 35vh;
  border-top: 1px solid ${divider.middle};
  border-right: 1px solid ${divider.middle};
  background-color: ${background.white};
  table {
    border-collapse: separate;
  }
  th,
  td {
    border-left: 1px solid ${divider.middle};
  }
  * {
    box-shadow: none;
  }
  .MuiToolbar-dense {
    display: none;
  }
  .MuiTableCell-head {
    padding: 8px !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    position: sticky;
    background-color: white;
    z-index: 500;
    top: 0;
  }

  .Mui-TableHeadCell-Content-Labels {
    > span {
      margin-left: 4px;
      img {
        opacity: 0.2;
      }
      .MuiBadge-badge {
        display: none;
      }
    }
  }
  .MuiTableCell-body {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  // Default
  .MuiTableRow-root {
    background-color: ${background.white};
    box-shadow: none;
    cursor: initial;
    .MuiTableCell-root {
      padding: 4px;
      border-color: ${divider.middle};
      opacity: 1;
    }
    &:not(.MuiTableRow-head):last-child {
      .MuiTableCell-root {
        border-bottom: 0;
      }
    }
  }
  // Hover
  .MuiTableRow-root:not(.MuiTableRow-head):hover {
    &,
    td {
      background-color: ${background.white};
      &::after {
        background-color: ${background.primary};
      }
    }
  }
  // Click/Pressed
  .Mui-selected {
    background-color: ${background.white} !important;
    td::after {
      background: ${background.secondary}!important;
    }
  }

  .MuiPaper-root {
    box-shadow: none;
  }
  .MuiPaper-root > .MuiBox-root {
    display: none;
  }

  .MuiCheckbox-root {
    color: ${button.primary}!important;
    height: 24px;
    width: 24px;
    padding: 0;
  }

  // Disabled Row
  .MuiTableCell-root.disabled {
    opacity: 1;
    background: ${background.disable}!important;
    pointer-events: none;
  }

  // added row
  .MuiTableCell-root.added {
    background: ${green[99]}!important;
    &::after {
      background: unset !important;
    }
  }

  // Failed row
  .MuiTableCell-root.failed {
    background: ${background.error}!important;
    &::after {
      background: unset !important;
    }
  }

  // Clickable row
  .MuiTableCell-root.clickable {
    cursor: pointer;
  }
`;
